body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide-label {
  display: none !important;
}
.compliance-table {
  border-collapse: collapse;
  width: 90%; 
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.compliance-content-center{
  text-align: center;
}

.compliance-table th, .compliance-table td {
  border: 1px solid lightgray;
  padding: 4px; /* Réduction du padding */
  text-align: left;
}

.compliance-table th {
  border: 1px solid black;
  background-color: #38a9b4;
  color:white;
}


.css-c1bgk0-RaLogin-root {
  background-image: radial-gradient(circle at 50% 14em, #90dfe7 0%, #288690 60%, #288690 100%) !important;
}
.css-1sz2sdz-MuiAvatar-root {
  background-color: #288690 !important;
}
.RaLogin-avatar{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
}
.forgot-password-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}


.MuiToolbar-root {
  background-color: white;
}
.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked{
  background-color: #4e73df;
}
.MuiMenuItem-root, .MuiMenuItem-root i{
  color: rgba(255,255,255,.8);
  white-space: normal !important;
  font-size: 20px;
}
a.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  color: white;
}
span.ra-input.ra-input-picture span {
  font-size: 16px;
}
.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall {
  border: 1px solid #d1d3e2;
  background-color: white;
  border-radius: 4px;
}
.css-kgu35d::before {
  border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}
.MuiList-root img {
  width: 120px;
}
.logoImg {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.ManagerPage .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.btnAction {
  background-color: #4e73df;
  padding: 17px 26px;
  border-radius: 7px;
  margin-bottom: 5px;
}

.btnAction i{
  color: white;
  font-size: 23px;
}
.ManagerPage .MuiGrid-root.MuiGrid-container {
  margin-bottom: 25px;
}

@media (min-width: 1250px) {
  .itemForm1 {
    padding-right: 15px;
  }
  .itemForm2 {
    padding-left: 15px;
  }
}


header .MuiToolbar-root svg {
  fill: #8e8e8e;
}
a.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  margin-bottom: 20px;
}
.RaMenu-open {
    background-color: #4e73df;
}
/*todo  enlever directement dans code*/
header .MuiToolbar-root>svg {
  display: none;
}
.MuiTextField-root.ra-input.ra-input-waterBodyType {
  display: none;
}

@media (max-width: 1250px) {
  .RaCreate-main .MuiGrid-root.MuiGrid-container {
    display: block !important;
  }
  .MuiCardContent-root {
    margin-bottom: 35px;
  }
}
.Managerbutton {
  background-color: green;
}
h1.title {
  margin-left: 20px;
  margin-top: 10px;
  font-size: 40px;

}
.ManagerButton{
  background-color: #146c43;
  color: white;
  padding: 8px;
  border: none;
  font-size: 15px;
}

.hidden {
  display: none !important;
}
.RaList-main .titleName {
  padding-left: 15px;
}

.img-balanceSheet{
  width:100%;
}

.custom-card .MuiTypography-root {
  font-size: 10px;
  font-weight: bold;
}

@media screen and (max-width:1250px) {
  .filter-container{
    justify-content: flex-start !important;
    flex-wrap: wrap;
  }
  .bodyTitle{
    width:80% !important;
  }
  .flextab{
    display:block !important;
  }
  .flextab2{
    flex-direction: column !important;
  }
  .divInfoFish{
    min-width:33%;
  }
  .filter{
    width:100% !important;
  }
  .indic{
    flex-direction: column;
  }
  .watercontainer{
    flex-wrap: wrap;
  }
  .watersection{
    min-width:100% !important;
  }
  .imgresp {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom:0 !important;
  }
  .equals{
    margin-right: 0;
    margin-left: 0;
  }
  .chart-icon{
    margin:0 !important;
  }
  .divInfoGlobal_2{
    min-height: 0 !important;
  }
  .divInfoCenter{
    margin: 0 0 30px 0 !important;
  }
  .tablew50{
    width:100% !important;
  }
  .order-container{
    display: flex;
    flex-direction: column;
  }
  .order-chart{
    order:3;
  }
  .order-icon {
    order: 2;
  }

  .order-tab {
    order: 1;
  }
  .chartspe{
    display: block !important;
    justify-content: center;
  }
  .chart-iconspe{
    display: flex;
    justify-content: center;
    margin: 30px 0 30px 0 !important;
  }
  .RaAutocompleteInput-textField{
    width:400px !important;
  }
  

}
.css-8xlivf-MuiAutocomplete-root-RaAutocompleteInput-root .MuiAutocomplete-tag{
  border-radius: 2px;
}
.RaAutocompleteInput-textField{
  /* width:200px; */
  margin-top:20px;
}
/* .css-8xlivf-MuiAutocomplete-root-RaAutocompleteInput-root .MuiFilledInput-root.MuiInputBase-sizeSmall {
  padding:30px 10px 10px 10px !important;
} */
.css-8xlivf-MuiAutocomplete-root-RaAutocompleteInput-root .MuiFilledInput-root.MuiInputBase-sizeSmall input{
  display: hidden;
}
.RaAutocompleteInput-textField p {
  display: none;
}

.chartspe{
  display: flex;
  width: 100% ;
  margin-bottom: 57;
}
.chartspediv{
  flex: 1 1 33%;
}
@media screen and (max-width:520px) {
  .filterCampaign{
    flex-direction: column;

  }
  .filterCampaign span, .filterCampaign .autocomplete-input, .filterCampaign .filter-icon{
    margin-bottom: 10px;
  }
  .dropdown-menu{
    width:400px !important;
  }
}
.order-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width:30%;

}

@media screen and (min-width:1250px) {
  .indic{
    flex-direction: row;
  }
}

.tablew50{
  width:50%;
}

.imgresp{

  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

}

.watercontainer{
  width:100%;
}
.watersection{
  min-width: 30%;
}
.equals{
  width:40px;
  height:40px;
  margin-right: -7;
  margin-left:-7;
  pointer-events: none;
}
.divInfoFish{
  display: block;
  text-align:center;
  margin:10px 0 10px 0;
}
.background-container{
  margin-top: 10px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  width:100%;
  background-color: rgb(251, 251, 251);

}


.background-container-info{
  margin-top: 10px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px 20px 10px 20px;

}
.chart-container {
  display: flex;
  width:100%;
  gap: 10px;


}

.chart-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin:40px;
  line-height: 1.3;

}

.chart-container > div {
  flex: 1;
}

.divInfoGlobal{
  margin-top: 40px;
  min-height:300px;
  width:100%;
}
.divInfoGlobal_2{
  margin-top: 10px;
  min-height:200px;
  width:100%;

}

.flextab {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.flextab2 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}




.flextab > div {
  flex-basis: 49%;
  box-sizing: border-box;
  padding: 5px;
  margin:5px;
}
.numberInfo{
  margin:0;
  font-size: 20px;
  font-weight: bold;
  color:#4e73df;
}

.invisibleInfo{
  margin:5px 0 0 0;
  font-size: 15px;
  font-weight: bold;
  color:black;
  text-align: center;
}
.invisibleInfo:hover{

  color:#4e73df;
  text-decoration: underline;
  cursor:pointer;
}
.divInfoCenter{
  text-align: center;
  margin:0 50px 0 50px;
}
.numberInfoCenter{
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #00ca65;
}
.textInfo{
  margin:0 0 0 0 ;
  color:gray;

}

.red{
  color:rgb(232, 0, 0);
}

.pTitle{
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 30px 0 0 0;
}
.pTitleLeft{
  font-weight: bold;
  font-size: 18px;
  margin: 30px 0 20px 0;
}
.pInfoWater{
  font-weight: bold;
  font-size: 20px;
  margin:0;
}
.pInfoWater2{
  font-size: 15px;
  margin:0;
}
.section-title {
  font-weight: bold;
  font-size: 25px;
  margin: 40px 0 0 10px;
  text-transform: uppercase;
}

.bodyTitle{
  font-weight: bold;
  font-size: 25px;
  margin: 0;
  text-transform: uppercase;
  width:40%;
}

.divMarginRight{
  margin-right: 40px;
}

.filter-container {
  display: flex;
  
  justify-content: flex-end;
  align-items: center;

}
.multirequester-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

}
.multirequester-filter{
  width:100%;
}

.filter {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin: 0 0 0 10px;


}
.boolean-filter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;


}

.dropdown-menu{
  width:500px;

  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}
.filterCampaign {
  display: flex;
  justify-content: space-between;
  align-items: center;


}


.filter > .autocomplete-input {
  width: 200px;
  margin: 0;
}

.autocomplete-input p {
  display: none;
}

.empty-text {
  position: relative;
}

.filter-icon {
  margin-right:10px;
}

.chart-icon{
  margin-top:250px;
}
.chart-icon2{
  margin-top:-250px;
}
.chart-icon-global{
  margin-top:140px;
}

.displayDemoBtn button{
  display: none;
}
.custom-select {
  position: relative;
  width: 200px;
}

.select-background {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 8px;
  height: 36px;
  font-size: 14px;
  color: #555555;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  line-height: 1.5715;
  border-radius: 2px;
  cursor: pointer;
}

.select-background.active {
  border-color: #40a9ff;
}

.custom-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-radius: 0 0 2px 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.select-background.active .custom-content {
  display: block;
}

.custom-content p {
  margin: 0;
  padding: 6px 12px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-content p:hover {
  background-color: #f0f0f0;
}

.custom-placeholder {
  color: #bfbfbf;
}

.css-n1sw1k-RaFilterForm-root {
  flex: unset !important;
}
.RaFilter-button {
  display: none !important;
}
